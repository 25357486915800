import { defineStore } from "pinia";
import type { Alert } from "@/definitions/types";
import { v4 as uuidV4 } from "uuid";

export const useAlertStore = defineStore("alert", {
  state: () => {
    return {
      items: [] as Alert[],
    };
  },
  actions: {
    toastSuccess(message: string, timer = 5_000) {
      this.alert(message, timer, "success", "mdi-check-circle");
    },
    toastError(message: string, timer = 30_000) {
      this.alert(message, timer, "error", "mdi-information");
    },
    toastInfo(message: string, timer = 30_000) {
      this.alert(message, timer, "info", "mdi-information");
    },
    toastWarning(message: string, timer = 30_000) {
      this.alert(message, timer, "warning", "mdi-alert");
    },
    alert(message: string, timer: number, type: string, icon: string) {
      this.$state.items = [
        ...this.$state.items,
        {
          uuid: uuidV4(),
          message: message,
          timer: timer,
          type: type,
          icon: icon,
        },
      ];
    },
  },
});
