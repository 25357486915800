<template>
  <div class="d-flex justify-center">
    <v-list
      class="pa-0"
      style="
        position: fixed;
        background-color: transparent;
        top: 0;
        z-index: 999;
      "
    >
      <transition-group name="scroll-y" mode="out-in">
        <TimerAlert
          v-for="item in items"
          :key="item.uuid"
          :value="item"
          @close="remove"
        />
      </transition-group>
    </v-list>
  </div>
</template>

<script setup lang="ts">
import { useAlertStore } from "@/stores/alert";
import { storeToRefs } from "pinia";
import TimerAlert from "@/components/dialog/TimerAlert.vue";

const { items } = storeToRefs(useAlertStore());
function remove(uuid: string): void {
  items.value = items.value.filter((item) => item.uuid !== uuid);
}
</script>
<style lang="scss" scoped>
.scroll-y-enter-active,
.scroll-y-leave-active {
  transition: all 0.3s ease;
}

.scroll-y-enter,
.scroll-y-leave-to {
  transform: translateY(-100%);
  opacity: 0;
}
</style>
