<template>
  <div>
    <template v-for="(item, _index) in items">
      <DialogConfirm
        :key="_index"
        :uuid="item.uuid"
        :title="item.title"
        :content="item.content"
        :width="item.width"
        :resolver="item.resolver"
        :confirm-button-title="item.confirmButtonTitle"
        :cancel-button-title="item.cancelButtonTitle"
      />
    </template>
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from "pinia";
import DialogConfirm from "@/components/dialog/DialogConfirm.vue";
import { useConfirmStore } from "@/stores/confirm";

const { items } = storeToRefs(useConfirmStore());
</script>
