import type {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import axios from "axios";
import { API_HOST, ENVIRONMENT } from "@/constants/envs";
import { stringify } from "qs";
import { useAlertStore } from "@/stores/alert";
import {
  getValidatedAccessToken,
  getValidatedRefreshToken,
  goSignInPage,
} from "@/utils/commands";
import { useAdminStore } from "@/stores/admin";
import jwt_decode from "jwt-decode";
import dayjs from "dayjs";

export interface ApiErrorResult {
  detail: string;
}
export const axiosInstance = axios.create({
  baseURL: `${API_HOST}api/`,
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
  },
});

axiosInstance.interceptors.request.use(
  async function (config) {
    if (!config.headers) {
      console.error("Failed to set 'request headers' : headers is not exist");
      return {} as InternalAxiosRequestConfig;
    }

    config.headers.Authorization = `Bearer ${await getValidatedAccessToken()}`;
    return config;
  },
  function (error) {
    toastAxiosError(error);
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  function (response) {
    if (response?.status?.toString().startsWith("2")) {
      return response;
    } else {
      console.error(response);
      return response;
    }
  },
  async function (error: AxiosError) {
    console.error(error.message);
    if (error.message === "Network Error") {
      const { toastError } = useAlertStore();
      toastError("Service Unavailable");
      return;
    }
    if (error.response) {
      if (error.response.headers.refreshtoken === "must") {
        const refreshToken = await apiRefreshToken(error);
        if (refreshToken?.config?.headers) {
          refreshToken.config.headers.Authorization =
            await getValidatedAccessToken();
          return axiosInstance.request(refreshToken.config);
        } else {
          return;
        }
      }
      if ([400, 401, 403, 404, 500].includes(error.response.status)) {
        if (error.response.status === 403) {
          const { toastWarning } = useAlertStore();
          toastWarning(getErrorMessage(error));
        } else if ([401].includes(error.response.status)) {
          await goSignInPage();
        }
        throw error;
      }
    }
    if (ENVIRONMENT === "local") {
      toastAxiosError(error);
    }
    console.error(error);
    return Promise.reject(error);
  },
);

export async function getApi<T = never, R = T>(url: string): Promise<R | null> {
  try {
    return (await axiosInstance.get<T, AxiosResponse<R>>(url)).data;
  } catch (e) {
    console.error(e);
    console.warn("No data returned from server");
    return null;
  }
}

export async function postApi<T = never, R = T>(
  url: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
  data: any,
  alertError = true,
): Promise<R | null> {
  try {
    return (await axiosInstance.post<T, AxiosResponse<R>>(url, data)).data;
  } catch (e) {
    console.error(e);
    if (alertError) {
      toastAxiosError(e);
    }
    return null;
  }
}

export async function putApi<T = never, R = T>(
  url: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
  data: any,
  alertError = true,
): Promise<R | null> {
  try {
    return (await axiosInstance.put<T, AxiosResponse<R>>(url, data)).data;
  } catch (e) {
    console.error(e);
    if (alertError) {
      toastAxiosError(e);
    }
    return null;
  }
}
export async function patchApi<T = never, R = T>(
  url: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
  data: any,
  alertError = true,
): Promise<R | null> {
  try {
    return (await axiosInstance.patch<T, AxiosResponse<R>>(url, data)).data;
  } catch (e) {
    console.error(e);
    if (alertError) {
      toastAxiosError(e);
    }
    return null;
  }
}

export async function deleteApi<T = never, R = T>(
  url: string,
  alertError = true,
): Promise<R | null> {
  try {
    return (await axiosInstance.delete<T, AxiosResponse<R>>(url)).data;
  } catch (e) {
    console.error(e);
    if (alertError) {
      toastAxiosError(e);
    }
    return null;
  }
}

export function getErrorMessage(e) {
  return (
    (e as AxiosError<ApiErrorResult>).response?.data?.detail ??
    (e as AxiosError).message ??
    "Service Unavailable. Please contact administrator or try later."
  );
}

export function toastAxiosError(e): void {
  const { toastError } = useAlertStore();
  toastError(getErrorMessage(e));
}

export async function downloadExcelApi(url: string): Promise<void> {
  const response = await axiosInstance.get<Blob>(`api/${url}`, {
    responseType: "blob",
    headers: {
      "Content-Type":
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    },
  });
  const newUrl = window.URL.createObjectURL(
    new Blob([response.data], { type: response.headers["content-type"] }),
  );
  const tempLink = document.createElement("a");
  tempLink.style.display = "none";
  tempLink.href = newUrl;
  const contentDisposition = response.headers["content-disposition"];
  tempLink.setAttribute(
    "download",
    (contentDisposition
      ? contentDisposition
          .split("=")
          .pop()
          ?.split(";")
          .join("")
          // eslint-disable-next-line
          .split('"')
          .join("")
      : url.split("/").pop()) || "",
  );
  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
  window.URL.revokeObjectURL(newUrl);
}

export function stringifyParams(obj): string {
  return stringify(
    Object.keys(obj)
      .filter((key) => typeof obj[key] !== "string" || obj[key].trim() !== "")
      .reduce((prev, key) => {
        prev[key] = obj[key];
        return prev;
      }, {}),
    { arrayFormat: "repeat", skipNulls: true },
  );
}

async function apiRefreshToken(error: AxiosError) {
  try {
    const refreshToken = window.localStorage.getItem("refreshToken");
    if (
      !refreshToken ||
      dayjs((jwt_decode(refreshToken) as { exp: number }).exp * 1000).isBefore(
        dayjs(),
      )
    ) {
      await goSignInPage();
    }
    const { reIssueAccessToken } = useAdminStore();
    await reIssueAccessToken();
  } catch (e) {
    console.error(e);
    await goSignInPage();
    return;
  }
  if (!error.config?.headers) {
    console.error("Failed to set 'request headers' : headers is not exist");
    return;
  }
  error.config.headers.AuthorizationR = await getValidatedRefreshToken();
  return error;
}
