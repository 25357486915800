import { defineStore } from "pinia";
import jwt_decode from "jwt-decode";
import { getNewToken, signOut } from "@/utils/commands";
import type { LoggedInAdmin } from "@/definitions/types";

export const useAdminStore = defineStore("admin", {
  state: (): LoggedInAdmin => {
    return {
      id: 0,
      loginId: "",
      accessToken: "",
    };
  },
  getters: {
    loggedIn: (state: LoggedInAdmin): boolean =>
      !!state.id &&
      !!state.accessToken &&
      !!localStorage.getItem("accessToken") &&
      !!localStorage.getItem("refreshToken"),
    admin: (state: LoggedInAdmin): LoggedInAdmin => {
      return {
        id: state.id,
        loginId: state.loginId,
        accessToken: state.accessToken,
      };
    },
  },
  actions: {
    clearAdmin(): void {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      this.$reset();
    },
    async reIssueAccessToken(): Promise<void> {
      const newToken = await getNewToken();
      if (newToken) {
        this.saveToken(newToken);
      }
    },
    saveToken(payload: { accessToken: string; refreshToken: string }): void {
      try {
        const jwt = jwt_decode<{
          exp: number;
          id: number;
          loginId: string;
        }>(payload.accessToken);
        localStorage.setItem("accessToken", payload.accessToken);
        localStorage.setItem("refreshToken", payload.refreshToken);
        this.id = jwt.id;
        this.loginId = jwt.loginId;
        this.accessToken = payload.accessToken;
      } catch (e: unknown) {
        console.error(e);
        signOut().then();
      }
    },
  },
  persist: true,
});
