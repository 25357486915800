<template>
  <div>
    <v-app-bar app dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title class="d-flex align-center">
        <img
          src="@/assets/images/logo/logo_white.svg"
          :alt="PRODUCT_TITLE"
          height="24"
          class="p-1"
        />
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon>
        <v-icon>mdi-bell-outline</v-icon>
      </v-btn>
      <toolbar-user></toolbar-user>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" app>
      <nav-menu v-if="drawerList.length > 0" :drawers="drawerList"></nav-menu>
    </v-navigation-drawer>
    <v-main>
      <v-container class="fill-height pa-0 pt-6 pb-6 pl-4 pr-4" :fluid="true">
        <v-layout>
          <slot />
          <input type="text" style="width: 0; height: 0" />
        </v-layout>
      </v-container>
    </v-main>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import type { Drawer } from "@/definitions/types";
import { MenuType } from "@/definitions/selections";
import { PRODUCT_TITLE } from "@/constants/envs";
import NavMenu from "@/components/navigation/NavMenu.vue";
import ToolbarUser from "@/components/toolbar/ToolbarUser.vue";

const drawer = ref(true);
const drawerList: Drawer[] = [
  {
    id: 1,
    name: "관리자",
    type: MenuType.GROUP,
    icon: "mdi-account",
    children: [
      {
        id: 11,
        name: "관리자 관리",
        type: MenuType.PAGE,
        url: "/admins",
      },
    ],
  },
];
</script>
