<template>
  <div>
    <v-alert
      :value="true"
      left
      light
      outlined
      text
      :icon="value.icon"
      width="360"
      :type="value.type"
      class="ma-1"
    >
      <div class="d-flex align-center">
        <span style="word-wrap: break-word; max-width: 250px">
          {{ value.message }}
        </span>
        <v-spacer />
        <v-icon
          :color="value.type"
          class="float-right"
          @click="emits('close', value.uuid)"
          >mdi-close</v-icon
        >
      </div>
    </v-alert>
  </div>
</template>
<script setup lang="ts">
import type { Alert } from "@/definitions/types";
import { onMounted } from "vue";

const props = defineProps<{
  value: Alert;
}>();

const emits = defineEmits<{
  (e: "close", v: string): void;
}>();

onMounted(() => {
  setTimeout(() => {
    emits("close", props.value.uuid);
  }, props.value.timer);
});
</script>
<style lang="scss" scoped>
.v-icon {
  cursor: pointer;
}
</style>
