import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import "@/plugins/vee-validate";
import { PiniaVuePlugin } from "pinia";
import vuetify from "@/plugins/vuetify";
import "@/assets/scss/index.scss";
import pinia from "@/stores";

Vue.use(PiniaVuePlugin);

new Vue({
  pinia,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
