import router from "@/router";
import type { SelectItem, AdminToken } from "@/definitions/types";
import axios from "axios";
import { API_HOST } from "@/constants/envs";
import { useAdminStore } from "@/stores/admin";
import { useAlertStore } from "@/stores/alert";
import dayjs from "dayjs";
import jwt_decode from "jwt-decode";
import { deleteApi } from "@/utils/apis";

export async function routerPush(path: string): Promise<void> {
  if (router.currentRoute.fullPath !== path) {
    await router.push(path);
  }
}
export async function routerReplace(path: string): Promise<void> {
  if (router.currentRoute.fullPath !== path) {
    await router.replace(path);
  }
}

export function getSelectionByObject(object): SelectItem[] {
  return Object.keys(object).map((key) => ({
    text: object[key],
    value: key,
  }));
}

export async function goSignInPage(): Promise<void> {
  const { clearAdmin } = useAdminStore();
  clearAdmin();
  await routerReplace("/sign-in");
}

export async function getNewToken(): Promise<
  | {
      accessToken: string;
      refreshToken: string;
    }
  | undefined
> {
  try {
    const response = await axios
      .create({
        baseURL: API_HOST,
        headers: {
          contentType: "application/json",
          AuthorizationR: await getValidatedRefreshToken(),
        },
      })
      .get<AdminToken>("api/v1/admins/token/refresh");
    return response.data;
  } catch (e: unknown) {
    if (axios.isAxiosError(e)) {
      const statusCode = e.response?.status || 500;
      if (statusCode === 401 || e.message === "Invalid token specified!") {
        await goSignInPage();
      } else if ([403, 404, 500].includes(statusCode)) {
        const { toastError } = useAlertStore();
        toastError(e.message);
      } else {
        const { toastError } = useAlertStore();
        console.warn(`Missing Status Code: ${statusCode}`);
        toastError(e.message);
      }
    } else {
      console.error(e);
    }
  }
}

export async function signOut(): Promise<void> {
  try {
    await deleteApi("v1/admins/sign-out", false);
  } catch (e) {
    console.error(e);
  }
  await goSignInPage();
}

export async function getValidatedAccessToken(): Promise<string> {
  let accessToken = window.localStorage.getItem("accessToken");
  if (!accessToken) {
    await goSignInPage();
    return "";
  }

  try {
    if (
      dayjs((jwt_decode(accessToken) as { exp: number }).exp * 1000).isBefore(
        dayjs(),
      )
    ) {
      const { reIssueAccessToken } = useAdminStore();
      await reIssueAccessToken();
      accessToken = window.localStorage.getItem("accessToken");
    }
  } catch (e: unknown) {
    await signOut();
  }
  return accessToken ?? "";
}

export async function getValidatedRefreshToken(): Promise<string> {
  const refreshToken = window.localStorage.getItem("refreshToken");
  if (
    !refreshToken ||
    dayjs((jwt_decode(refreshToken) as { exp: number }).exp * 1000).isBefore(
      dayjs(),
    )
  ) {
    await goSignInPage();
  }
  return refreshToken ?? "";
}

export function rememberIdAndPwd(loginId: string, password: string): void {
  localStorage.setItem("remember", "yes");
  localStorage.setItem("rememberId", loginId);
  localStorage.setItem("rememberPwd", password);
}

export function clearRememberedIdAndPwd(): void {
  localStorage.removeItem("remember");
  localStorage.removeItem("rememberId");
  localStorage.removeItem("rememberPwd");
}
