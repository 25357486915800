<template>
  <v-app>
    <input type="text" style="width: 0; height: 0" />
    <component :is="currentLayout" v-if="isRouterLoaded">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </component>
    <GlobalAlert />
    <GlobalDialogConfirm />
    <input type="text" style="width: 0; height: 0" />
  </v-app>
</template>

<script setup lang="ts">
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import { PRODUCT_TITLE } from "@/constants/envs";
import { computed, onMounted } from "vue";
import { useRoute } from "vue-router/composables";
import GlobalDialogConfirm from "@/components/dialog/GlobalDialogConfirm.vue";
import AuthLayout from "@/layouts/AuthLayout.vue";
import GlobalAlert from "@/components/dialog/GlobalAlert.vue";

const route = useRoute();
const layouts = {
  default: DefaultLayout,
  auth: AuthLayout,
};
const isRouterLoaded = computed((): boolean => route.name !== null);
const currentLayout = computed(() => layouts[route.meta?.layout || "default"]);

onMounted(() => {
  document.title = PRODUCT_TITLE;
});
</script>
